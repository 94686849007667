import { LightIcon } from "../Shared/Icons/light";

export const ExpandIcon = ({ isActive }: { isActive: boolean }) => {
	return (
		<LightIcon
			icon={{
				icon: isActive ? "angle-up" : "angle-down",
				size: "2x"
			}}
			className={"ml10px"}
		/>
	);
};